import getStateValue from '../../../../../helpers/getStateValue';
import dom from '../../../../../wrapper/DomWrapper';
import { DEFAULT_STORE_URL } from '../constants';

import getDashProductUrl from './getDashProductUrl';

/**
 * Go to product page
 * @param {number|string} id
 * @param {string} name
 * @param {string=} sku
 * @param {string=} productUrlFormat
 * @param {boolean=} isDashStore
 */
const goToProductPage = (id, name, sku, productUrlFormat, isDashStore) => {
  const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);
  let path = `${storeUrl}/${name.replaceAll(/[^\dA-Za-z]/g, '-')}-p${id}`;

  if (isDashStore) {
    path = `${storeUrl}/${getDashProductUrl(id, name, sku, productUrlFormat)}`;
  }

  dom.window.location.pathname = path;
};

export default goToProductPage;
