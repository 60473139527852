import CatalogPage from '../../../../implementation/Ecommerce/ecwid/custom/CatalogPage';
import ProductPage from '../../../../implementation/Ecommerce/ecwid/custom/Product';
import StorefrontPage from '../../../../implementation/Ecommerce/ecwid/custom/Storefront/Storefront';
import DashCartPage from '../../../../implementation/Ecommerce/ecwid/dash/CartPage';
import DashProductPage from '../../../../implementation/Ecommerce/ecwid/dash/ProductPage';
import dom from '../../../../wrapper/DomWrapper';

import getProductId from './utils/getProductId';
import {
  CATALOG_PAGE_WRAPPER_ID,
  DASH_PRODUCT_PAGE_WRAPPER_ID,
  PAGE_TYPE,
  PRODUCT_PAGE_WRAPPER_ID,
  STOREFRONT_PAGE_WRAPPER_ID,
} from './constants';
import {
  getPageType,
  goToCartPage,
  goToCatalogPage,
  goToProductPage,
} from './utils';

const WIDGET_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.PRODUCT_PAGE, new ProductPage(null)],
  [PAGE_TYPE.CART_PAGE, new StorefrontPage(null)],
  [PAGE_TYPE.CATALOG_PAGE, new CatalogPage(null)],
  [PAGE_TYPE.DASH_PRODUCT_PAGE, new DashProductPage(null)],
  [PAGE_TYPE.DASH_STORE_PAGE, new CatalogPage(null)],
  [PAGE_TYPE.DASH_CART_PAGE, new DashCartPage(null)],
]);

const WRAPPER_ID_BY_PAGE_TYPE = new Map([
  [PAGE_TYPE.PRODUCT_PAGE, PRODUCT_PAGE_WRAPPER_ID],
  [PAGE_TYPE.DASH_PRODUCT_PAGE, DASH_PRODUCT_PAGE_WRAPPER_ID],
  [PAGE_TYPE.CART_PAGE, STOREFRONT_PAGE_WRAPPER_ID],
  [PAGE_TYPE.CATALOG_PAGE, CATALOG_PAGE_WRAPPER_ID],
  [PAGE_TYPE.DASH_STORE_PAGE, CATALOG_PAGE_WRAPPER_ID],
]);

class Router {
  static goToCatalogPage = (...args) => {
    goToCatalogPage(...args);
  };

  static goToCartPage = (...args) => {
    goToCartPage(...args);
  };

  static goToProductPage = (...args) => {
    goToProductPage(...args);
  };

  constructor({ isDashStore = false } = {}) {
    this.href = dom.window.location.href;
    this.isDashStore = isDashStore;
    this.type = this.getPageType();
  }

  init = () => {
    if (!WIDGET_BY_PAGE_TYPE.has(this.type)) return;

    const page = WIDGET_BY_PAGE_TYPE.get(this.type);

    this.showPage();
    page.init({
      id: this.getProductId(),
    });
  };

  getPageType = () => getPageType({ href: this.href, isDashStore: this.isDashStore });

  getProductId = () => {
    if (![PAGE_TYPE.PRODUCT_PAGE, PAGE_TYPE.DASH_PRODUCT_PAGE].includes(this.type)) return null;

    return getProductId(this.href);
  };

  // eslint-disable-next-line consistent-return
  showPage = () => {
    if (!WRAPPER_ID_BY_PAGE_TYPE.has(this.type)) return null;

    const currentPageWrapperId = WRAPPER_ID_BY_PAGE_TYPE.get(this.type);

    dom.show(dom.getElement(`#${currentPageWrapperId}`));
  };
}

export default Router;
