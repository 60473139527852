import getStateValue from '../../../../../helpers/getStateValue';
import dom from '../../../../../wrapper/DomWrapper';
import { DEFAULT_STORE_URL } from '../constants';

const goToCatalogPage = () => {
  const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

  dom.window.location.pathname = `${storeUrl}/`;
};

export default goToCatalogPage;
