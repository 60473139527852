import flowRight from 'lodash/flowRight';
import isFunction from 'lodash/isFunction';
import memoize from 'lodash/memoize';

import getStateValue from '../helpers/getStateValue';
import dom from '../wrapper/DomWrapper';

import ROUTES from './constants/routes';
import Product from './entities/product.model';
import { DEFAULT_BOOKING_STORE_TAG } from './router/constants';

const getBookingConfig = (companyId) => ({
  headers: {
    // eslint-disable-next-line camelcase
    unverified_product_instance_id: companyId,
  },
});

class BookingProvider {
  constructor() {
    this.bookingId = getStateValue('booking.bookingId');
    this.companyId = getStateValue('booking.ecomCompanyId');
    this.bookingTag = getStateValue('booking.bookingTag') || DEFAULT_BOOKING_STORE_TAG;
    this.bookingService = getStateValue('apiUrls.bookingService');

    if (!this.bookingId || !this.bookingService) return;

    this.fetchProductsMemoize = memoize(async (instanceId, companyId) => {
      const productsResponse = await dom.window.fetch(ROUTES.allProducts(instanceId), getBookingConfig(companyId));

      if (!productsResponse.ok) throw new Error(productsResponse.status.toString(10));

      return productsResponse.json();
    });

    this.fetchCategoriesMemoize = memoize(async (instanceId, companyId) => {
      const categoriesResponse = await dom.window.fetch(ROUTES.categories(instanceId), getBookingConfig(companyId));

      if (!categoriesResponse.ok) throw new Error(categoriesResponse.status.toString(10));

      return categoriesResponse.json();
    });

    const OnAPILoaded = async () => {
      try {
        this.apiLoaded = true;

        if (isFunction(this.apiLoadedResolver)) {
          this.apiLoadedResolver(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    OnAPILoaded();
  }

  isReady = memoize(() => new Promise((resolve) => {
    if (this.apiLoaded) {
      resolve(true);

      return;
    }

    this.apiLoadedResolver = resolve;
  }));

  getCategories = async () => {
    await this.isReady();

    const categories = await this.fetchCategoriesMemoize(this.bookingId, this.companyId);

    const items = categories.map(({ id, name }) => ({
      id,
      name,
    }));

    return {
      items,
      total: items.length,
    };
  };

  getProduct = async (id, settings) => {
    await this.isReady();

    const products = await this.fetchProductsMemoize(this.bookingId, this.companyId);
    const product = (products || []).find((el) => el?.id === id);

    if (!product) throw new Error('404');
    if (product.hidden) throw new Error('hidden');

    return this.transformProduct(product, settings);
  };

  getProducts = async (params) => {
    await this.isReady();

    const {
      offset = 0,
      limit = 1000,
      category,
    } = params || {};

    const products = await this.fetchProductsMemoize(this.bookingId, this.companyId);
    const preparedData = flowRight([
      (data) => data.map((product) => this.transformProduct(product)),
      (data) => (category ? data.filter(({ category_id: categoryId }) => categoryId === category) : data),
      (data) => (data.filter(({ hidden }) => !hidden)),
    ])(products);

    return {
      ...params,
      ...preparedData,
      // eslint-disable-next-line unicorn/explicit-length-check
      total: preparedData.length,
      items: preparedData.slice(offset, (offset + limit)),
    };
  };

  transformProduct = (productData, settings) => {
    const {
      bookingId,
      bookingService,
    } = this;
    const product = new Product({
      bookingId,
      bookingService,
      ...settings,
    });
    const {
      id,
      category_id: categoryId,
      image_url: image,
      name,
      tagline: shortDescription,
      description,
      price_description: price,
      duration,
    } = productData;

    product.id = id;
    product.categoryId = categoryId;
    product.image = image;
    product.name = name;
    product.shortDescription = shortDescription;
    product.description = description;
    product.priceFormatted = price;
    product.duration = duration;

    return product;
  };
}

export default BookingProvider;
