import SelectWidget from 'choices.js';

import ecommerce from '../../../../../ecommerce/ecwid/custom';
import { goToCartPage } from '../../../../../ecommerce/ecwid/custom/router/utils';
import dom from '../../../../../wrapper/DomWrapper';
import { SELECT_CLASS_NAMES } from '../../../../Form/constants';
import {
  PRODUCT_ONE_TIME_PURCHASE_INPUT_ID, PRODUCT_SUBSCRIPTION_BUTTON_ID,
  PRODUCT_SUBSCRIPTION_INPUT_ID,
  PRODUCT_SUBSCRIPTION_PRICE_ID,
} from '../../custom/Product/constants';

import { CLASS_NAMES } from './constants';

class ProductSubscription {
  constructor(elWrapper, product) {
    this.elWrapper = elWrapper;
    this.product = product;
    this.elPrice = null;
    this.elWrapperPrice = null;
    this.elSubscribeButton = null;
    this.elSelect = null;
  }

  init = () => {
    this.initPrice();
    this.toggleSubscription(false);
    this.initFrequencySelect();
    this.initRadioInputs();
    this.initSubscribeButton();
  };

  update = (product) => {
    this.product = {
      ...this.product,
      ...product,
    };
    this.updatePrice();
    this.updateSubscribeButton();
  };

  initPrice = () => {
    const {
      price,
      subscriptionDetails,
      withSubscriptionDiscount,
    } = this.product;

    const elPrice = dom.getElement(`#${PRODUCT_SUBSCRIPTION_PRICE_ID}`);

    if (!elPrice) return;

    const elWrapperPrice = elPrice.closest('.ecom-product__price');
    const { discount_percentage: discount } = subscriptionDetails;
    const subscriptionPrice = withSubscriptionDiscount ? price - ((price * discount) / 100) : price;

    if (withSubscriptionDiscount && elWrapperPrice) {
      dom.addClass(elWrapperPrice, '_with-discount');
    } else {
      dom.removeClass(elWrapperPrice, '_with-discount');
    }

    dom.addText(elPrice, ecommerce.provider.formatPrice(subscriptionPrice));

    this.elPrice = elPrice;
    this.elWrapperPrice = elWrapperPrice;
  };

  updatePrice = () => {
    if (!this.elPrice) return;

    const {
      price,
      subscriptionDetails,
      withSubscriptionDiscount,
    } = this.product;

    const { discount_percentage: discount } = subscriptionDetails;
    const subscriptionPrice = withSubscriptionDiscount ? price - ((price * discount) / 100) : price;

    if (withSubscriptionDiscount && this.elWrapperPrice) {
      dom.addClass(this.elWrapperPrice, '_with-discount');
    } else {
      dom.removeClass(this.elWrapperPrice, '_with-discount');
    }

    dom.addText(this.elPrice, ecommerce.provider.formatPrice(subscriptionPrice));
  };

  initFrequencySelect = () => {
    const elSelect = dom.getElement('[data-widget="select"][name="frequency-select"]', this.elWrapper);

    if (!elSelect) return;

    const { subscriptionDetails } = this.product;
    const selectItems = subscriptionDetails.subscription_plan || [];
    const optionsHtml = selectItems.map(({ plan_id: planId, plan_name: planName }) => `<option value="${planId}">${planName}</option>`);

    elSelect.insertAdjacentHTML('beforeend', optionsHtml.join(''));

    // eslint-disable-next-line no-new
    new SelectWidget(elSelect, {
      searchEnabled: false,
      shouldSort: false,
      itemSelectText: '',
      classNames: SELECT_CLASS_NAMES,
    });
    this.elSelect = elSelect;
  };

  initRadioInputs = () => {
    const inputSubscription = dom.getElement(`#${PRODUCT_SUBSCRIPTION_INPUT_ID}`);
    const inputOneTimeBuy = dom.getElement(`#${PRODUCT_ONE_TIME_PURCHASE_INPUT_ID}`);

    [inputSubscription, inputOneTimeBuy].forEach((input) => dom.on(input, 'click', this.handleInputChange));
  };

  initSubscribeButton = () => {
    const elSubscribeButton = dom.getElement(`#${PRODUCT_SUBSCRIPTION_BUTTON_ID}`);

    if (!elSubscribeButton) return;

    const { canBuy } = this.product;

    if (!canBuy) dom.addClass(elSubscribeButton, CLASS_NAMES.showTooltip);

    dom.on(elSubscribeButton, 'click', this.handleSubscriptionAction);

    this.elSubscribeButton = elSubscribeButton;
  };

  updateSubscribeButton = () => {
    if (!this.elSubscribeButton) return;

    const { canBuy } = this.product;

    if (canBuy) {
      dom.removeClass(this.elSubscribeButton, CLASS_NAMES.showTooltip);
    } else {
      dom.addClass(this.elSubscribeButton, CLASS_NAMES.showTooltip);
    }
  };

  handleInputChange = (e) => {
    this.toggleSubscription(e.target.value === 'default');
  };

  handleSubscriptionAction = async () => {
    const {
      id,
      canBuy,
      subscriptionDetails,
    } = this.product;

    if (!canBuy || !this.elSelect) return;

    const planId = this.elSelect.value;
    const planName = this.elSelect.selectedOptions[0].textContent;

    if (!planId) return;

    await ecommerce.provider.cart.itemSubscribe({
      productId: id,
      quantity: 1,
      planId,
      planName,
      subscriptionId: subscriptionDetails.id,
    });
    goToCartPage();
  };

  toggleSubscription = (isDefault = true) => {
    if (isDefault) {
      dom.addClass(this.elWrapper, 'product-default-selected');
      dom.removeClass(this.elWrapper, 'product-subscription-selected');
    } else {
      dom.addClass(this.elWrapper, 'product-subscription-selected');
      dom.removeClass(this.elWrapper, 'product-default-selected');
    }
  };
}

export default ProductSubscription;
