/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

import { ADD_TO_CART, GO_TO_CHECKOUT } from '../../../../../../components/Ecommerce/Ecwid/Custom/constants';
import delay from '../../../../../../helper/delay';
import { SHARING_BUTTONS_WIDGET_SELECTOR } from '../../../../../constants';
import ecommerce from '../../../../../ecommerce/ecwid/custom';
import checkProjectWithAnimation from '../../../../../helpers/checkProjectWithAnimation';
import { addInViewWait, removeInViewWait } from '../../../../../helpers/inView';
import { updateMetaTags } from '../../../../../helpers/seo';
import dom from '../../../../../wrapper/DomWrapper';
import ItemRenderer from '../../../../ItemsView/renderer';
import { Parallax } from '../../../../Parallax';
import extendShemaOrg from '../../../../SchemaOrg';
import SharingButtons from '../../../../SharingButtons';
import {
  PRODUCT_BADGES_ID,
  PRODUCT_BANNER_ID,
  PRODUCT_BUY_BUTTON_ID,
  PRODUCT_DESC_ID,
  PRODUCT_MAIN_IMAGE_ID,
  PRODUCT_NAME_ID,
  PRODUCT_OPTIONS_ID,
  PRODUCT_PREVIOUS_PRICE_ID,
  PRODUCT_QUANTITY_ID,
  PRODUCT_QUANTITY_IN_CART_ID,
  PRODUCT_QUANTITY_INPUT_ID,
  PRODUCT_REGULAR_PRICE_ID,
  PRODUCT_REST_IMAGE_ID,
  PRODUCT_SAVED_PERCENT_ID,
  PRODUCT_SHARE_ID,
  PRODUCT_SHIPPING_ID,
  PRODUCT_SKU_ID, PRODUCT_SUBSCRIPTION_BUTTON_ID, PRODUCT_SUBSCRIPTION_OPTIONS,
} from '../../custom/Product/constants';

import {
  CLASS_NAMES,
} from './constants';
import ProductGallery from './ProductGallery';
import ProductOptions from './ProductOptions';
import ProductSubscription from './ProductSubscription';
import { getDefaultProduct } from './utils';

class ProductPage {
  constructor() {
    this.state = {
      product: {},
      order: {
        quantityToAdd: 1,
        options: {},
        selectedOptions: {},
      },
      isAdding: false,
    };
    this.productId = null;
    this.defaultProductId = null;
    this.elWrapper = null;
    this.elContainer = null;
    this.elContent = null;
    this.settings = null;
    this.contentRenderer = null;
    this.sharingButtons = null;
    this.productOptions = [];
    this.elementsAnimation = [];
    this.gallery = null;
    this.subscription = null;
  }

  get selectedOptions() {
    return this.state.order.selectedOptions;
  }

  init = async (data) => {
    const elPage = dom.getElement('.js-widget[data-widget="dashProductPage"]');

    if (!elPage || !data?.id) return;

    try {
      const { settings: settingsStr } = elPage.dataset;
      const settings = JSON.parse(settingsStr);

      this.productId = data?.id;
      this.defaultProductId = data?.defaultId;
      this.elContainer = dom.getElement(`#${settings?.containerId}`);
      this.elContent = dom.getElement(`#${settings?.contentId}`);
      this.elAdditionalContent = dom.getElement(`#${settings?.additionalId}`);
      this.elWrapper = this.elContainer?.parentNode;
      this.settings = settings;

      if (!this.elContainer) return;

      this.withAnimation = checkProjectWithAnimation();

      this.showSpinner();

      await this.fetchProduct(this.productId);

      this.renderMetadata();

      if (!this.productWithOptions) await this.handleQuantityInCartChange();

      await this.render();

      if (!this.productWithOptions) await this.updateCart();

      this.updatePrice();
      this.initQuantityInCart();

      if (this.productWithOptions) this.initOptions();

      this.initBuyButton();
      this.initSchemaOrg();
      this.initSharingButtons();
      this.initSubscription();

      Parallax.forceUpdate();

      await this.initAnimation();
      this.hideSpinner();
      this.updateAnimation();
    } catch (error) {
      console.error(error);
    }
  };

  getSubscriptionProductData = () => {
    const {
      state: { product }, defaultProductId, productWithOptions,
    } = this;
    const {
      subscriptionDetails, withSubscriptionDiscount,
    } = product;

    const defaultProduct = getDefaultProduct({
      defaultProductId,
      product,
      productWithOptions,
    });

    return {
      ...defaultProduct,
      canBuy: this.canBuy(),
      subscriptionDetails: defaultProduct?.subscriptionDetails || subscriptionDetails,
      withSubscriptionDiscount: defaultProduct?.withSubscriptionDiscount || withSubscriptionDiscount,
    };
  };

  initSubscription = () => {
    const {
      elWrapper, state: { product },
    } = this;
    const { isSubscriptionAvailable } = product;

    if (!isSubscriptionAvailable) return;

    const productData = this.getSubscriptionProductData();

    if (isNil(productData.subscriptionDetails)) return;

    const subscription = new ProductSubscription(elWrapper, productData);

    subscription.init();
    this.subscription = subscription;
  };

  updateSubscription = () => {
    const { subscription } = this;

    if (!subscription) return;

    const productData = this.getSubscriptionProductData();

    if (isNil(productData.subscriptionDetails)) return;

    subscription.update(productData);
  };

  initSharingButtons = () => {
    this.sharingButtons = new SharingButtons(SHARING_BUTTONS_WIDGET_SELECTOR);

    this.sharingButtons.init();
  };

  initSchemaOrg = () => {
    const {
      name, price, compareToPrice, url, sku, description, condition, images, inStock, quantity,
    } = this.state.product;

    extendShemaOrg('Product', ['@context'], 'https://schema.org/');
    extendShemaOrg('Product', ['name'], name);
    extendShemaOrg('Product', ['image'], images.map((image) => image.imageOriginalUrl));
    extendShemaOrg('Product', ['sku'], sku);

    if (description) {
      const descriptionWithoutTags = description.replaceAll(/<\/?[^>]+(>|$)/g, '');

      extendShemaOrg('Product', ['description'], descriptionWithoutTags);
    }

    extendShemaOrg('Product', ['offers', '@type'], 'Offer');
    extendShemaOrg('Product', ['offers', 'price'], price.toString());
    extendShemaOrg('Product', ['offers', 'url'], url);
    extendShemaOrg(
      'Product',
      ['offers', 'availability'],
      inStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'
    );
    extendShemaOrg('Product', ['offers', 'itemCondition'], condition === 'NEW' ? 'https://schema.org/NewCondition' : 'https://schema.org/UsedCondition');

    if (compareToPrice) {
      extendShemaOrg('Product', ['offers', 'offers', '@type'], 'AggregateOffer');
      extendShemaOrg('Product', ['offers', 'offers', 'highPrice'], compareToPrice.toString());
      extendShemaOrg('Product', ['offers', 'offers', 'lowPrice'], price.toString());

      if (quantity) extendShemaOrg('Product', ['offers', 'offers', 'offerCount'], quantity.toString());
    }
  };

  initAnimation = async () => {
    if (!this.withAnimation) return;

    const ids = [
      PRODUCT_MAIN_IMAGE_ID,
      PRODUCT_REST_IMAGE_ID,
      PRODUCT_NAME_ID,
      PRODUCT_SKU_ID,
      PRODUCT_REGULAR_PRICE_ID,
      PRODUCT_PREVIOUS_PRICE_ID,
      PRODUCT_SAVED_PERCENT_ID,
      PRODUCT_DESC_ID,
      PRODUCT_SHARE_ID,
      PRODUCT_SUBSCRIPTION_BUTTON_ID,
      PRODUCT_SUBSCRIPTION_OPTIONS,
      PRODUCT_BANNER_ID,
      ...this.subscription ? [] : [
        PRODUCT_QUANTITY_ID,
        PRODUCT_QUANTITY_IN_CART_ID,
        PRODUCT_BUY_BUTTON_ID,
      ],
    ];

    this.elementsAnimation = [
      ...ids.map((id) => dom.getElement(`#${id}`, this.elContainer)),
      ...dom.getCollection(`#${PRODUCT_BADGES_ID} > div`, this.elContainer) || [],
      ...dom.getCollection(`#${PRODUCT_OPTIONS_ID} > div`, this.elContainer) || [],
      ...dom.getCollection(`#${PRODUCT_SHIPPING_ID} > p`, this.elContainer) || [],
    ].filter(Boolean);

    if (this.elementsAnimation.length === 0) return;

    addInViewWait(this.elementsAnimation);
    await delay(400); // css transition delay
  };

  updateAnimation = (elements = this.elementsAnimation) => {
    if (!this.withAnimation || elements.length === 0) return;

    setTimeout(() => {
      removeInViewWait(elements);
    }, 0);
  };

  updateCart = async ({ isReset = false } = {}) => {
    this.updateBuyButton();
    await this.updateQuantityInput(isReset);
    await this.updateQuantityInCart();
  };

  initBuyButton = () => {
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);
    const {
      options,
    } = this.state.product;

    const defaultProduct = get(options, '[0].defaultProduct');

    if (defaultProduct) dom.addClass(elBuyButton, CLASS_NAMES.showTooltip);

    dom.on(elBuyButton, 'click', this.handleButtonAction);
  };

  handleButtonAction = async () => {
    const { buttonAction } = this.settings;

    switch (buttonAction) {
      case ADD_TO_CART: {
        await this.addToCart();

        break;
      }

      case GO_TO_CHECKOUT: {
        await this.addToCart(() => goToCartPage(true));

        break;
      }
      default: {
        console.error('wrong buttonAction');

        break;
      }
    }
  };

  updateBuyButton = () => {
    const productQuantity = this.getProductQuantity();
    const isDisabled = !productQuantity;
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    if (!elBuyButton) return;

    elBuyButton.disabled = isDisabled;
  };

  updateBuyButtonTooltip = () => {
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    if (!elBuyButton) return;

    if (this.canBuy()) {
      dom.removeClass(elBuyButton, CLASS_NAMES.showTooltip);
    } else {
      dom.addClass(elBuyButton, CLASS_NAMES.showTooltip);
    }
  };

  initOptions = () => {
    const {
      inStock,
      options,
    } = this.state.product;

    if (!inStock) return;

    const elOptionsContainer = dom.getElement(`#${PRODUCT_OPTIONS_ID}`);

    (options || []).forEach(({
      variants,
      products,
      defaultProduct,
    }) => {
      variants?.forEach(({
        name,
        choices,
      }) => {
        const productOption = new ProductOptions(
          name,
          choices,
          (price) => ecommerce.provider.formatPrice(price)
        );

        productOption.connect(
          elOptionsContainer,
          this.handleChangeOption(products, variants, defaultProduct)
        );

        this.productOptions.push(productOption);

        if (this.checkOutOfStock()) this.hideProductOptions();
      });
    });
  };

  findProductOptions = (optionName) => {
    if (!isArray(this.productOptions)) return [];

    return optionName
      ? this.productOptions.filter(({ name }) => (name === optionName))
      : this.productOptions;
  };

  clearOptions = (optionName) => {
    const options = this.findProductOptions(optionName);

    options.forEach((instance) => {
      instance?.clear();
    });
  };

  resetOptions = (optionName) => {
    const options = this.findProductOptions(optionName);

    options.forEach((instance) => {
      instance?.clear();
      instance?.unCheck();
      this.removeOption(instance?.name);
      this.removeSelectedOption(instance?.name);
    });
  };

  handleChangeOption = (products, variants, defaultProduct) => async (e) => {
    const { name, value } = e.target;

    const otherVariants = variants.filter((variant) => variant.name !== name);

    otherVariants.forEach((variant) => {
      this.clearOptions(variant.name);
    });

    if (this.selectedOptions[name] === value) {
      this.resetOptions(name);
    } else {
      this.setOption(name, value);
      this.setSelectedOption(name, value);
    }

    variants.reduce((acc, variant) => {
      if (!acc) this.removeOption(variant.name);

      if (variant.name === name) return false;

      return acc;
    }, true);

    this.updatePrice(products, variants, defaultProduct);
    this.updateGallery();
    this.updateSubscription();

    if (this.productWithOptions) await this.correctProductOptionQuantity();

    await this.updateCart({ isReset: true });
  };

  getProductId = () => this.defaultProductId || this.productId;

  getProductOptionsData = () => {
    const productId = this.getProductId();
    const products = get(this, 'state.product.options[0].products', []);
    const index = products.findIndex((product) => product.id === productId);

    return {
      product: products[index],
      path: `state.product.options[0].products[${index}]`,
    };
  };

  getProductQuantity = () => {
    if (!this.productWithOptions) return this.state.product.quantity;

    const { product } = this.getProductOptionsData();

    return product?.quantity || 0;
  };

  updateProductQuantity = (quantity) => {
    const prepareQuantity = quantity < 0 ? 0 : quantity;

    if (!this.productWithOptions) {
      this.updateProduct({
        quantity: prepareQuantity,
      });

      return;
    }

    const { path } = this.getProductOptionsData();

    set(this, `${path}.quantity`, prepareQuantity);
  };

  correctProductOptionQuantity = async () => {
    const { path, product } = this.getProductOptionsData();

    if (product?.withCorrectedQuantity) return;

    const countInCart = await this.getQuantityInCart();
    const productQuantity = this.getProductQuantity();
    const availableQuantity = productQuantity - countInCart;

    this.updateProductQuantity(availableQuantity);
    set(this, `${path}.withCorrectedQuantity`, true);
  };

  getQuantityInCart = async () => {
    let countInCart;

    try {
      countInCart = await ecommerce.provider.cart.itemGetQuantity(this.getProductId());
    } catch {
      countInCart = 0;
    }

    return countInCart;
  };

  setOption = (optionName, value) => {
    if (isNil(value)) return;

    this.state.order.options[optionName] = value;
  };

  removeOption = (optionName) => {
    delete this.state.order.options[optionName];
  };

  setSelectedOption = (optionName, value) => {
    if (isNil(value)) return;

    this.selectedOptions[optionName] = value;
  };

  removeSelectedOption = (optionName) => {
    delete this.selectedOptions[optionName];
  };

  canBuy = () => {
    if (!this.productWithOptions) return true;

    const allSelectedOptions = Object.keys(this.selectedOptions);
    const allOptions = this.state.product.options[0]?.variants;

    return allOptions.length === allSelectedOptions.length;
  };

  updatePrice = (products, variants, defaultProduct) => {
    const elRegularPrice = dom.getElement(`#${PRODUCT_REGULAR_PRICE_ID}`);
    const elPreviousPrice = dom.getElement(`#${PRODUCT_PREVIOUS_PRICE_ID}`);
    const elSavedPercent = dom.getElement(`#${PRODUCT_SAVED_PERCENT_ID}`);

    if (isEmpty(variants)) {
      const defaultProductId = get(this.state.product, 'options[0].defaultProduct.defaultId');

      if (defaultProductId) this.defaultProductId = defaultProductId;
    } else {
      const allSetOptions = Object.keys(this.state.order.options);

      if (isEmpty(allSetOptions)) {
        this.defaultProductId = defaultProduct.defaultId;

        this.resetOptions();
        this.updateProduct({
          price: defaultProduct.price,
          compareToPrice: defaultProduct.compareToPrice,
        });
        this.updateBuyButtonTooltip();
      } else {
        const variantIds = allSetOptions.reduce((acc, option) => {
          const value = this.state.order.options[option];
          const findOption = variants.find((variant) => variant.name === option);
          const findVariant = findOption.choices.find((choice) => choice.text === value);

          return [
            ...acc,
            findVariant.id,
          ];
        }, []);

        const enabledProducts = products
          .filter((el) => variantIds.reduce((acc, variantId) => {
            if (!acc) return acc;

            return Object.values(el.variations).some((variation) => variation.choiceId === variantId);
          }, true));

        const updatedVariants = variants
          .map((variant) => {
            const {
              choices,
            } = variant;

            return {
              ...variant,
              choices: choices.map((choice) => ({
                ...choice,
                disabled: !enabledProducts?.some(
                  (product) => Object.values(product.variations).some((variation) => variation.choiceId === choice.id)
                ),
              })),
            };
          })
          .filter((variant) => !allSetOptions.includes(variant.name));

        updatedVariants.forEach((variant) => {
          const { name, choices } = variant;

          choices.forEach((choice) => {
            const {
              disabled,
              text,
            } = choice;

            const elChoice = dom.getElement(`[name="${name}"][value="${text}"]`);

            elChoice.disabled = disabled;

            if (disabled) {
              dom.addClass(elChoice.parentElement, CLASS_NAMES.showTooltip);
              elChoice.checked = false;

              if (this.selectedOptions[name] === text) this.removeSelectedOption(name);
            } else {
              dom.removeClass(elChoice.parentElement, CLASS_NAMES.showTooltip);
            }
          });
        });

        if (enabledProducts.length > 0) {
          const lowPriceProduct = enabledProducts.sort((a, b) => a.price - b.price)[0];

          this.defaultProductId = lowPriceProduct.id;

          this.updateProduct({
            price: lowPriceProduct.price,
            compareToPrice: lowPriceProduct.compareToPrice,
          });
        }

        this.updateBuyButtonTooltip();
      }
    }

    const price = ecommerce.provider.getPrice(
      this.state.product.price,
      1
    );

    const previousPrice = ecommerce.provider.getPreviousPrice(
      this.state.product.price,
      this.state.product.compareToPrice,
      1
    );

    const savedPercent = ecommerce.provider.getSavedPercent(price, previousPrice);

    dom.addText(elRegularPrice, `${ecommerce.provider.formatPrice(price)}`);
    dom.addText(
      elPreviousPrice,
      previousPrice ? `${ecommerce.provider.formatPrice(previousPrice)}` : ''
    );
    dom.addText(elSavedPercent, savedPercent ? ` Save ${savedPercent}%` : '');
  };

  checkOutOfStock = () => this.state.product.isOutOfStock();

  // eslint-disable-next-line class-methods-use-this
  hideProductOptions = () => {
    const container = dom.getElement(`#${PRODUCT_OPTIONS_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  setState = (nexState = {}) => {
    this.state = {
      ...this.state,
      ...nexState,
    };

    return this.state;
  };

  fetchProduct = async (productId) => {
    console.warn('product id', productId);

    try {
      const productData = await ecommerce.provider.getProduct(productId, true);

      this.updateProduct({
        ...productData,
      });

      this.productWithOptions = this.state.product.checkWithOptions();
    } catch (error) {
      console.error(error);
    }
  };

  updateProduct = (data = {}) => {
    this.setState({
      product: {
        ...this.state.product,
        ...data,
      },
    });
  };

  handleQuantityInCartChange = async () => {
    const productQuantity = this.getProductQuantity();
    const countInCart = await this.getQuantityInCart();
    const availableQuantity = productQuantity - countInCart;

    this.updateProductQuantity(availableQuantity);
  };

  addToCart = async (cb) => {
    if (this.state.isAdding || !this.canBuy()) return;

    if (this.productWithOptions) await this.correctProductOptionQuantity();

    const productQuantity = this.getProductQuantity();

    if (!productQuantity) {
      await this.updateCart();

      return;
    }

    const quantityAddToCart = this.state.order.quantityToAdd;
    const prepareQuantityAddToCart = Math.min(quantityAddToCart, productQuantity);
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    if (elBuyButton) dom.addClass(elBuyButton, CLASS_NAMES.buttonSpinner);

    this.state.isAdding = true;
    dom.addClass(this.elContainer, CLASS_NAMES.isAdding);

    try {
      await ecommerce.provider.cart.itemAdd(this.getProductId(), prepareQuantityAddToCart, {}, async () => {
        this.updateProductQuantity(productQuantity - prepareQuantityAddToCart);
        await this.animateBuyButton(elBuyButton);
        await this.updateCart();
        this.state.isAdding = false;
        dom.removeClass(this.elContainer, CLASS_NAMES.isAdding);

        if (isFunction(cb)) cb();
      });
    } catch {
      dom.removeClass(elBuyButton, CLASS_NAMES.buttonSpinner);
      dom.removeClass(this.elContainer, CLASS_NAMES.isAdding);
      this.state.isAdding = false;
    }
  };

  initQuantityInCart = () => {
    const elQuantityInput = dom.getElement(`#${PRODUCT_QUANTITY_INPUT_ID}`);

    dom.on(elQuantityInput, 'change', this.handleChangeQuantity);
  };

  updateQuantityInput = async (isReset) => {
    const productQuantity = this.getProductQuantity();
    const elQuantityInput = dom.getElement(`#${PRODUCT_QUANTITY_INPUT_ID}`);

    if (!elQuantityInput) return;

    if (productQuantity) {
      elQuantityInput.min = 1;

      if (isReset) {
        elQuantityInput.value = 1;
        this.state.order.quantityToAdd = 1;
      }

      elQuantityInput.max = productQuantity;
      elQuantityInput.disabled = false;

      return;
    }

    const countInCart = await this.getQuantityInCart();

    elQuantityInput.min = countInCart;
    elQuantityInput.value = countInCart;
    elQuantityInput.max = countInCart;
    elQuantityInput.disabled = true;
  };

  getQuantityInCartText = async () => {
    const { getTranslate = () => {}, isDigital } = this.state.product;

    if (isDigital) return getTranslate('se.wf.ecom_digital_product_in_the_cart');

    const countInCart = await this.getQuantityInCart();
    const prefix = countInCart > 1 ? 'se.wf.ecom_product_in_the_cart_items' : 'se.wf.ecom_product_in_the_cart_item';

    return `${countInCart} ${getTranslate(prefix)} ${getTranslate('se.wf.ecom_product_in_the_cart_no_more')}`;
  };

  updateQuantityInCart = async () => {
    const productQuantity = this.getProductQuantity();
    const elQuantityInCart = dom.getElement(`#${PRODUCT_QUANTITY_IN_CART_ID}`);

    if (!elQuantityInCart) return;
    if (productQuantity) {
      dom.addClass(elQuantityInCart, CLASS_NAMES.hidden);
      dom.addText(elQuantityInCart, '');

      return;
    }

    const text = await this.getQuantityInCartText();

    dom.addText(elQuantityInCart, text);
    dom.removeClass(elQuantityInCart, CLASS_NAMES.hidden);
    this.updateAnimation(elQuantityInCart);
  };

  handleChangeQuantity = (e) => {
    const { value } = e.target;
    const parseIntValue = Number.parseInt(value, 10);
    const mathAbsValue = Math.abs(value);

    if (parseIntValue < 0) {
      // eslint-disable-next-line no-param-reassign
      e.target.value = mathAbsValue;
      this.state.order.quantityToAdd = mathAbsValue;
    } else if (parseIntValue === 0) {
      // eslint-disable-next-line no-param-reassign
      e.target.value = 1;
      this.state.order.quantityToAdd = 1;
    } else {
      this.state.order.quantityToAdd = mathAbsValue;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  animateBuyButton = async (elBuyButton) => {
    if (!elBuyButton) return;

    dom.removeClass(elBuyButton, CLASS_NAMES.buttonSpinner);
    dom.addClass(elBuyButton, CLASS_NAMES.buttonActive);

    await delay(2000);

    dom.removeClass(elBuyButton, CLASS_NAMES.buttonActive);
  };

  showSpinner = () => {
    dom.addClass(this.elWrapper, CLASS_NAMES.spinner);
  };

  hideSpinner = () => {
    dom.removeClass(this.elWrapper, CLASS_NAMES.spinner);
  };

  initGallery = () => {
    const {
      defaultProductId, elContainer, settings, state: { product }, productWithOptions,
    } = this;
    const defaultProduct = getDefaultProduct({
      defaultProductId,
      product,
      productWithOptions,
    });
    const defaultImages = defaultProduct?.images || product?.images;

    if (defaultImages?.length === 0) {
      dom.addClass(elContainer, CLASS_NAMES.noGallery);

      return;
    }

    const gallery = new ProductGallery(defaultImages, settings);

    gallery.init();
    this.gallery = gallery;
  };

  updateGallery = () => {
    const {
      defaultProductId, state: { product }, productWithOptions, gallery,
    } = this;

    if (!gallery) return;

    const defaultProduct = getDefaultProduct({
      defaultProductId,
      product,
      productWithOptions,
    });
    const defaultImages = defaultProduct?.images || product?.images;

    if (defaultImages?.length === 0) return;

    gallery.update(defaultImages);
  };

  renderContent = () => {
    if (!this.elContent) return;

    const { templateContentId } = this.settings;
    const renderer = this.contentRenderer || new ItemRenderer(dom.getElement(`#${templateContentId}`), {
      imports: { forEach, isNil },
    });

    this.elContent.innerHTML = renderer.render({ product: this.state.product });
  };

  renderAdditionalContent = () => {
    if (!this.elAdditionalContent) return;

    const { templateAdditionalId } = this.settings;
    const renderer = this.contentRenderer || new ItemRenderer(dom.getElement(`#${templateAdditionalId}`), {
      imports: { forEach, isNil },
    });

    this.elAdditionalContent.innerHTML = renderer.render({ product: this.state.product });
  };

  render = () => {
    this.initGallery();
    this.renderContent();
    this.renderAdditionalContent(); // thirty column
  };

  renderMetadata = () => {
    const {
      name,
      metaTitle,
      metaDescription,
      images = [],
    } = this.state.product;

    updateMetaTags({
      type: 'product',
      name,
      title: metaTitle || name,
      description: metaDescription,
      image: images[0],
      isOriginalImage: true,
    });
  };
}

export default ProductPage;
