import getStateValue from '../../../helpers/getStateValue';
import dom from '../../../wrapper/DomWrapper';

/**
 * Connect Ecwid script
 * @returns {Promise<unknown>}
 */
const connectEcwidScript = () => new Promise((resolve, reject) => {
  const existingScript = dom.getElement('#store-script');

  if (existingScript) dom.removeElement(existingScript);

  const script = dom.createElement('script');

  script.id = 'store-script';
  script.type = 'text/javascript';
  script.charset = 'utf8';
  script.src = `https://app.shopsettings.com/script.js?${getStateValue('ecommerce.storeId')}`;
  script.defer = true;
  script.dataset.cfasync = 'false';

  script.addEventListener('load', resolve);
  script.addEventListener('error', reject);

  dom.document.body.append(script);
});

export default connectEcwidScript;
