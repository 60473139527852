import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';

import dom from '../../../wrapper/DomWrapper';

/**
 * Check closed store front
 * @param {function} resolve
 * @returns {{reset: function}}
 */
const checkClosedStoreFront = (resolve) => {
  let watchIntervalId = null;

  const initWatcher = ({
    obj, propName, handler, timeout = 1500,
  } = {}) => {
    if (
      !isNil(watchIntervalId)
      || !isPlainObject(obj)
    ) return;

    watchIntervalId = setInterval(
      () => isFunction(handler) && handler(obj[propName]),
      timeout
    );
  };

  const resetWatcher = () => {
    if (isNil(watchIntervalId)) return;

    clearInterval(watchIntervalId);
    watchIntervalId = null;
  };

  initWatcher({
    obj: dom.window.ecwid_initial_data?.data,
    propName: 'storeClosed',
    handler: (value) => {
      if (isNil(value)) return;

      resetWatcher();
      resolve();
    },
  });

  return {
    reset: resetWatcher,
  };
};

export default checkClosedStoreFront;
