import ecommerce from '../../../../../../ecommerce/ecwid/custom';
import getStateValue from '../../../../../../helpers/getStateValue';
import dom from '../../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../../wrapper/WidgetWrapper';
import {
  RETRY_BUTTON_ID, RETRY_CONTAINER_ID,
  SPINNER_CONTAINER_ID,
} from '../../constants';

class CartPage extends WidgetWrapper {
  async init() {
    this.elSpinner = dom.getElement(`#${SPINNER_CONTAINER_ID}`);
    this.elRetry = dom.getElement(`#${RETRY_CONTAINER_ID}`);
    this.elRetryButton = dom.getElement(`#${RETRY_BUTTON_ID}`);
    this.storeId = getStateValue(['ecommerce', 'storeId']);

    this.showSpinner();
    await this.initStorefront();
  }

  initStorefront = async () => {
    try {
      await ecommerce.provider.initStorefront(this.selector);
      dom.off(this.elRetryButton, 'click', this.reconnect);
    } catch (error) {
      console.error(error);
      this.showRetry();
      dom.on(this.elRetryButton, 'click', this.reconnect.bind(this));
    } finally {
      this.hideSpinner();
    }
  };

  reconnect = async () => {
    this.hideRetry();
    this.init();
  };

  showRetry() {
    dom.show(this.elRetry);
  }

  hideRetry() {
    dom.hide(this.elRetry);
  }

  showSpinner() {
    dom.show(this.elSpinner);
  }

  hideSpinner() {
    dom.hide(this.elSpinner);
  }
}

export default CartPage;
