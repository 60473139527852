import { PRODUCTS_ORDER_MAP_ECWID } from '../../../../../../../components/Ecommerce/Ecwid/Custom/constants';
import { prepareDataWithMap } from '../../../utils';

export const MAP_DASH_SORT = {
  [PRODUCTS_ORDER_MAP_ECWID.id_desc]: {
    key: 'orderbydesc',
    value: 'createdDate',
  },
  [PRODUCTS_ORDER_MAP_ECWID.id_asc]: {
    key: 'orderby',
    value: 'createdDate',
  },
  [PRODUCTS_ORDER_MAP_ECWID.price_desc]: {
    key: 'orderbydesc',
    value: 'price',
  },
  [PRODUCTS_ORDER_MAP_ECWID.price_asc]: {
    key: 'orderby',
    value: 'price',
  },
  [PRODUCTS_ORDER_MAP_ECWID.name_desc]: {
    key: 'orderbydesc',
    value: 'name',
  },
  [PRODUCTS_ORDER_MAP_ECWID.name_asc]: {
    key: 'orderby',
    value: 'name',
  },
};

export const CATEGORY_DATA_MAP_KEYS = {
  enabled: 'is_visible',
  id: 'id',
  name: 'display_name',
  productIds: 'productIds',
};

export const CATEGORIES_DATA_MAP_KEYS = {
  items: {
    path: 'categories',
    handler: (categories) => (categories || [])
      .filter((category) => category.is_visible)
      .map((category) => prepareDataWithMap(CATEGORY_DATA_MAP_KEYS, category))
      .filter(Boolean),
  },
};
