import { ECWID_DASH_PAGE_TYPE } from '../../../../components/Ecommerce/constants';
import getStateValue from '../../../helpers/getStateValue';

import DashProvider from './infrastructure/dashProvider';
import EcwidProvider from './infrastructure/ecwidProvider';

class Ecommerce {
  constructor(providerType) {
    switch (providerType) {
      case ECWID_DASH_PAGE_TYPE: {
        this.provider = new DashProvider();

        break;
      }
      default: {
        this.provider = new EcwidProvider(providerType);
      }
    }
  }
}

export default new Ecommerce(getStateValue('ecommerce.viewProvider'));
