import isEmpty from 'lodash/isEmpty';
import lozad from 'lozad';

import browser from '../../helpers/browser';
import { doIEObjectFitPolyfill } from '../../polyfills';
import dom from '../../wrapper/DomWrapper';
import imageErrorHandler from '../Image';

import { getLoadImageConfig, onLoadImage } from './utils';

const isIE = browser.isIe();

export const lazy = lozad('.lazy', {
  ...getLoadImageConfig(onLoadImage),
  loaded(el) {
    if (isIE && el.tagName === 'IMG') setTimeout(() => doIEObjectFitPolyfill(el), 0);

    if (el.tagName !== 'PICTURE') return;

    const image = dom.getElement('img', el);

    if (!image) return;

    const classes = (el.dataset.classnames || '').split(' ');
    const { alt } = el.dataset;
    const { title } = el.dataset;

    if (!isEmpty(classes)) classes.forEach((className) => dom.addClass(image, className));

    if (alt) image.setAttribute('alt', alt);

    if (title) image.setAttribute('title', title);

    imageErrorHandler();
  },
});

export default () => {
  lazy.observe();
};
