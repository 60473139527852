import get from 'lodash/get';
import memoize from 'lodash/memoize';

import getStateValue from '../../../helpers/getStateValue';

export default memoize(async () => {
  const geoIpService = getStateValue(['apiUrls', 'geoipService']);

  if (!geoIpService) return new Promise((resolve) => resolve(null));

  let sessionId = sessionStorage.getItem('sid');

  if (!sessionId) {
    sessionId = `${Math.random().toString(36).slice(5)}-${Math.random().toString(36).slice(5)}-${Math.random().toString(36).slice(5)}`;
    sessionStorage.setItem('sid', sessionId);
  }

  const geoDataResponse = await fetch(`${geoIpService}/geoip?sid=${sessionId}`);

  if (!get(geoDataResponse, ['ok'])) return new Promise((resolve) => resolve(null));

  return geoDataResponse.json();
});
