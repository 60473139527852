import ecommerce from '../../../../../../ecommerce/ecwid/custom';
import checkProjectWithAnimation from '../../../../../../helpers/checkProjectWithAnimation';
import getStateValue from '../../../../../../helpers/getStateValue';
import { addInViewWait, removeInViewWait } from '../../../../../../helpers/inView';
import dom from '../../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../../wrapper/WidgetWrapper';
import {
  PAGE_CONTAINER_ID,
  RETRY_BUTTON_ID,
  RETRY_CONTAINER_ID,
  SPINNER_CONTAINER_ID,
} from '../../constants';
import { addGalleryListener } from '../../utils';

class CatalogPage extends WidgetWrapper {
  async init() {
    this.elPage = dom.getElement(`#${PAGE_CONTAINER_ID}`);
    this.elSpinner = dom.getElement(`#${SPINNER_CONTAINER_ID}`);
    this.elRetry = dom.getElement(`#${RETRY_CONTAINER_ID}`);
    this.elRetryButton = dom.getElement(`#${RETRY_BUTTON_ID}`);
    this.storeId = getStateValue(['ecommerce', 'storeId']);
    this.withAnimation = checkProjectWithAnimation();

    this.showSpinner();
    this.initAnimation();
    await this.initStorefront();
  }

  initAnimation = () => {
    if (!this.withAnimation) return;

    addInViewWait(this.elPage);
  };

  updateAnimation = () => {
    if (!this.withAnimation) return;

    removeInViewWait(this.elPage);
  };

  initStorefront = async () => {
    try {
      await ecommerce.provider.initStorefront(this.selector);
      addGalleryListener();
      dom.off(this.elRetryButton, 'click', this.reconnect);
    } catch (error) {
      console.error(error);
      this.showRetry();
      dom.on(this.elRetryButton, 'click', this.reconnect.bind(this));
    } finally {
      this.hideSpinner();
      this.updateAnimation();
    }
  };

  reconnect = async () => {
    this.hideRetry();
    this.init();
  };

  showRetry() {
    dom.show(this.elRetry);
  }

  hideRetry() {
    dom.hide(this.elRetry);
  }

  showSpinner() {
    dom.show(this.elSpinner);
  }

  hideSpinner() {
    dom.hide(this.elSpinner);
  }
}

export default CatalogPage;
