import getStateValue from '../../../../../helpers/getStateValue';
import dom from '../../../../../wrapper/DomWrapper';
import {
  CATALOG_PAGE_WRAPPER_ID,
  DASH_PRODUCT_PAGE_WRAPPER_ID,
  DEFAULT_STORE_URL, PAGE_TYPE,
} from '../constants';

import isCartPage from './isCartPage';
import isCatalogPage from './isCatalogPage';
import isProductPage from './isProductPage';

/**
 * Get page type
 * @param {{ href: string, isDashStore: boolean }}
 * @returns {null|string}
 */
const getPageType = ({ href, isDashStore }) => {
  const storeUrl = getStateValue(['ecommerce', 'storeUrl'], DEFAULT_STORE_URL);

  if (isDashStore) {
    const elProductPage = dom.getElement(`#${DASH_PRODUCT_PAGE_WRAPPER_ID}`);
    const elStorePage = dom.getElement(`#${CATALOG_PAGE_WRAPPER_ID}`);

    if (isCartPage(href, storeUrl, isDashStore)) return PAGE_TYPE.DASH_CART_PAGE;
    if (elProductPage) return PAGE_TYPE.DASH_PRODUCT_PAGE;
    if (elStorePage) return PAGE_TYPE.DASH_STORE_PAGE;
  }

  if (isProductPage(href, storeUrl)) {
    return PAGE_TYPE.PRODUCT_PAGE;
  }

  if (isCartPage(href, storeUrl)) return PAGE_TYPE.CART_PAGE;

  if (isCatalogPage(href, storeUrl)) return PAGE_TYPE.CATALOG_PAGE;

  return null;
};

export default getPageType;
