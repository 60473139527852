import { ECWID_DASH_PAGE_TYPE } from '../../../components/Ecommerce/constants';
import ecommerce from '../../ecommerce/ecwid/custom';
import { goToCartPage } from '../../ecommerce/ecwid/custom/router/utils';
import { isLight } from '../../helpers/color';
import getStateValue from '../../helpers/getStateValue';
import dom from '../../wrapper/DomWrapper';
import WidgetWrapper from '../../wrapper/WidgetWrapper';

import {
  BASKET_CONTAINER,
  TOTAL_BUDGE_CONTAINER,
  TOTAL_BUDGE_TEXT,
} from './constants';

class CartButton extends WidgetWrapper {
  state = {
    quantity: 0,
  };

  viewProvider = getStateValue('ecommerce.viewProvider');

  static refreshCartButtonWidget = async (container) => {
    const quantity = await ecommerce.provider.cart.getTotalQuantity();

    const elBudge = dom.getElement(`.${TOTAL_BUDGE_CONTAINER}`, container);
    const elBudgeText = dom.getElement(`.${TOTAL_BUDGE_TEXT}`, container);

    dom.updateStyle(elBudge, {
      display: quantity > 0
        ? 'block'
        : 'none',
    });

    dom.addText(elBudgeText, `${quantity}`);
  };

  init = async () => {
    this.updateElements();

    if (!this.isCartButtonMounted()) return;

    const iconColor = dom.window.getComputedStyle(this.elBudgeWrapper).color || '#000';
    const basketColor = dom.window.getComputedStyle(this.elBasket).color || '#000';
    const isIconLightColor = isLight(iconColor);
    const isBasketLightColor = isLight(basketColor);

    if (isIconLightColor) dom.addClass(this.elBudgeText, 'light-color');
    if (isBasketLightColor) dom.addClass(this.elBasket, '_dark');

    try {
      this.state.quantity = await ecommerce.provider.cart.getTotalQuantity();
    } catch {
      this.state.quantity = 0;
    }

    this.setProductQuantityInCart();
    this.cartListeners();

    ecommerce.provider.cart.onChange.add(this.handleProductQuantityChange);
    dom.on(this.elBasket, 'click', this.handleButtonClick);
  };

  cartListeners = () => {
    if (this.viewProvider !== ECWID_DASH_PAGE_TYPE) return;

    const originRemoveItem = dom.window.localStorage.removeItem.bind(window.localStorage);
    const originSetItem = dom.window.localStorage.setItem.bind(window.localStorage);

    Storage.prototype.removeItem = (name) => {
      originRemoveItem(name);

      if (name === 'stand-alone-checkout-cart') {
        this.state.quantity = 0;
        this.setProductQuantityInCart();
      }
    };

    Storage.prototype.setItem = (name, value) => {
      originSetItem(name, value);

      if (name === 'stand-alone-checkout-cart') {
        ecommerce.provider.cart.getTotalQuantity().then((productsQuantity) => {
          this.handleProductQuantityChange({
            productsQuantity,
          });
        });
      }
    };
  };

  isCartButtonMounted = () => [
    this.elBasket,
    this.elBudge,
    this.elBudgeText,
    this.elBudgeWrapper,
    this.elBasket]
    .some(Boolean);

  updateElements = () => {
    const widgetElement = dom.getElement(this.selector);

    this.elBudge = dom.getElement(`.${TOTAL_BUDGE_CONTAINER}`, widgetElement);
    this.elBudgeText = dom.getElement(`.${TOTAL_BUDGE_TEXT}`, widgetElement);
    this.elBudgeWrapper = dom.getElement(`.${TOTAL_BUDGE_CONTAINER} .total`, widgetElement);
    this.elBasket = dom.getElement(`.${BASKET_CONTAINER}`, widgetElement);
  };

  setProductQuantityInCart = () => {
    this.updateElements();

    if (this.state.quantity > 0) {
      this.showTotalBudge();
      dom.addText(this.elBudgeText, `${this.state.quantity}`);

      return;
    }

    this.hideTotalBudge();
    dom.addText(this.elBudgeText, '0');
  };

  showTotalBudge = () => {
    dom.updateStyle(this.elBudge, { display: 'block' });
  };

  hideTotalBudge = () => {
    dom.updateStyle(this.elBudge, { display: 'none' });
  };

  handleProductQuantityChange = ({ productsQuantity = 0 }) => {
    this.state.quantity = productsQuantity;
    this.setProductQuantityInCart();
  };

  // eslint-disable-next-line class-methods-use-this
  handleButtonClick = () => {
    goToCartPage();
  };
}

export default CartButton;
