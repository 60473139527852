import { ECWID_CUSTOM_PAGE_TYPE, ECWID_NATIVE_PAGE_TYPE } from '../../../../components/Ecommerce/constants';
import { PAGE_TYPE } from '../custom/router/constants';

const { CATALOG_PAGE, PRODUCT_PAGE, CART_PAGE } = PAGE_TYPE;
const CUSTOM_DYNAMIC_PAGE_TYPE = new Set([CART_PAGE]); // ecwid v3
const NATIVE_DYNAMIC_PAGE_TYPE = new Set([CATALOG_PAGE, PRODUCT_PAGE, CART_PAGE]); // ecwid v3 dashboard

/** Get dynamic pages by provider type
 * @param {string} providerType
 * @returns {Set<string>|*[]}
 */
const getDynamicPages = (providerType) => {
  if (providerType === ECWID_CUSTOM_PAGE_TYPE) return CUSTOM_DYNAMIC_PAGE_TYPE;
  if (providerType === ECWID_NATIVE_PAGE_TYPE) return NATIVE_DYNAMIC_PAGE_TYPE;

  return new Set([]);
};

/** Check is dynamic page by provider type and page type
 * @param {string} providerType
 * @param {string} pageType
 * @returns {boolean}
 */
const checkDynamicPage = (providerType, pageType) => {
  const dynamicPages = getDynamicPages(providerType);

  return dynamicPages.has(pageType);
};

export default checkDynamicPage;
