import isEmpty from 'lodash/isEmpty';

import { getBorderWidthSettings } from '../../../../helper/borderSettings';
import dom from '../../../wrapper/DomWrapper';

import {
  GALLERY_CONTAINER_SELECTOR,
  IMAGE_CONTAINER_SELECTOR,
  IMAGE_GALLERY_BORDER_BLOCK,
  IMAGE_GALLERY_CONTAINER_SELECTOR,
  IMAGE_INNER_BORDER_SELECTOR,
} from './constants';

const convertBorderRadius = (container, borderRadius = 0) => {
  if (!container) return borderRadius;

  const containerWidth = dom.getElementWidth(container);
  const containerHeight = dom.getElementHeight(container);

  return Math.min(containerWidth, containerHeight) * (borderRadius / 100);
};

/**
 * Util for setting inline filter shadow to an image container
 * @param {Element=} container
 * @param {string=} filter
 */
const setFilterShadow = (container, filter) => {
  if (!container || !filter) return;

  dom.updateStyle(container, { filter });
};

export const getBorderRadiusSettings = ({
  container = null,
  isMixedRadius,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
}) => {
  let updatedBorderTopLeftRadius = 0;
  let updatedBorderTopRightRadius = 0;
  let updatedBorderBottomRightRadius = 0;
  let updatedBorderBottomLeftRadius = 0;
  let updatedBorderRadius = 0;

  if (isMixedRadius) {
    updatedBorderTopLeftRadius = convertBorderRadius(container, borderTopLeftRadius);
    updatedBorderTopRightRadius = convertBorderRadius(container, borderTopRightRadius);
    updatedBorderBottomRightRadius = convertBorderRadius(container, borderBottomRightRadius);
    updatedBorderBottomLeftRadius = convertBorderRadius(container, borderBottomLeftRadius);
  } else {
    updatedBorderRadius = convertBorderRadius(container, borderRadius);
  }

  return {
    ...!isMixedRadius && {
      borderRadius: `${updatedBorderRadius}px`,
    },
    ...isMixedRadius && {
      borderTopLeftRadius: `${updatedBorderTopLeftRadius}px ${updatedBorderTopLeftRadius}px`,
      borderTopRightRadius: `${updatedBorderTopRightRadius}px ${updatedBorderTopRightRadius}px`,
      borderBottomRightRadius: `${updatedBorderBottomRightRadius}px ${updatedBorderBottomRightRadius}px`,
      borderBottomLeftRadius: `${updatedBorderBottomLeftRadius}px ${updatedBorderBottomLeftRadius}px`,
    },
  };
};

/**
 * Util for setting inline border radius to an image container
 * Shows element after calculation
 * @param {Element} image
 */
export const setImageBorderRadius = (image) => {
  if (!image) return;

  const container = image.closest(IMAGE_CONTAINER_SELECTOR);

  if (!container || !container.dataset?.settings) return;

  const settings = JSON.parse(container.dataset?.settings);

  if (isEmpty(settings)) return;

  const {
    isMixedRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    filter,
  } = settings;

  if (!borderRadius && !isMixedRadius) {
    setFilterShadow(container, filter);

    return;
  }

  const innerBorder = dom.getElement(IMAGE_INNER_BORDER_SELECTOR, container);
  const borderRadiusSettings = getBorderRadiusSettings({
    container,
    isMixedRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  });

  dom.updateStyle(container, borderRadiusSettings);

  if (innerBorder) dom.updateStyle(innerBorder, borderRadiusSettings);

  dom.showOpacity(container);
  setFilterShadow(container, filter);
};

const setGalleryImageBorderWidth = ({
  borderWidthSettings,
  borderRadiusSettings,
  wrapper,
  borderTopWidth,
  borderRightWidth,
  borderBottomWidth,
  borderLeftWidth,
}) => {
  const isOneOfWidthExist = [
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
  ].some(
    (width) => width > 0
  );

  if (!isOneOfWidthExist) return;

  const galleryBorderBlock = dom.createElement('div');

  galleryBorderBlock.setAttribute('class', IMAGE_GALLERY_BORDER_BLOCK);

  wrapper.append(galleryBorderBlock);

  dom.updateStyle(galleryBorderBlock, {
    ...borderWidthSettings,
    ...borderRadiusSettings,
  });
};

const setGalleryImageBorderRadius = ({
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  showCaption,
  borderRadiusSettings,
  wrapper,
}) => {
  const isOneOfRadiiExist = [
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  ].some(
    (radius) => radius > 0
  );

  if (!isOneOfRadiiExist) return;

  dom.updateStyle(wrapper, {
    margin: (isOneOfRadiiExist && showCaption) ? '8px' : null,
    ...borderRadiusSettings,
  });
};

const getGallerySettings = (image) => {
  const gallery = image.closest(GALLERY_CONTAINER_SELECTOR);

  if (!gallery || !gallery.dataset?.settings) return;

  return JSON.parse(gallery.dataset?.settings);
};

export const setGalleryImageBorderSettings = (image) => {
  if (!image) return;

  const settings = getGallerySettings(image);

  if (isEmpty(settings)) return;

  const wrapper = image.closest(IMAGE_GALLERY_CONTAINER_SELECTOR);

  const {
    isMixedRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    borderWidth,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    isMixedWidth,
    borderColor,
    showCaption,
  } = settings;

  const borderRadiusSettings = getBorderRadiusSettings({
    container: wrapper,
    isMixedRadius,
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  });

  const borderWidthSettings = getBorderWidthSettings({
    borderWidth,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
    isMixedWidth,
    borderColor,
  });

  setGalleryImageBorderWidth({
    borderWidthSettings,
    borderRadiusSettings,
    wrapper,
    borderTopWidth,
    borderRightWidth,
    borderBottomWidth,
    borderLeftWidth,
  });

  setGalleryImageBorderRadius({
    borderRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    showCaption,
    borderRadiusSettings,
    wrapper,
  });
};
