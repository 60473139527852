import SelectWidget from 'choices.js';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

import { DEFAULT_CATEGORY } from '../../../../../../components/Ecommerce/Ecwid/Custom/constants';
import dom from '../../../../../wrapper/DomWrapper';
import { SELECT_CLASS_NAMES } from '../../../../Form/constants';

const SELECT_CONFIG = {
  searchEnabled: false,
  shouldSort: false,
  itemSelectText: '',
  classNames: SELECT_CLASS_NAMES,
};

class Sort {
  constructor(elWrapper, layoutInstance) {
    this.elWrapper = elWrapper;
    this.layoutInstance = layoutInstance;
    this.fetchParams = {
      productIds: true,
      // eslint-disable-next-line camelcase
      hidden_categories: false,
    };

    this.init();
  }

  init = async () => {
    await this.initCategories();
    this.initSort();
  };

  initCategories = async () => {
    const { isDashStore, categories } = this.layoutInstance;
    const items = categories || await this.fetchCategories();
    const filterItems = isDashStore
      ? items
      : items.filter(({ enabled, productIds }) => !!enabled && !!productIds); // for v3 store

    this.renderCategories(filterItems);
  };

  getCategories = async () => {
    const getter = this.layoutInstance?.components?.provider?.getCategories;

    if (!isFunction(getter)) {
      throw new Error('Wrong configuration');
    }

    return getter(this.fetchParams);
  };

  fetchCategories = async () => {
    try {
      const { items } = await this.getCategories() || {};

      return isArray(items) ? items : [];
    } catch (error) {
      console.error(error);

      return [];
    }
  };

  renderCategories = (items) => {
    const elSelect = dom.getElement('[data-widget="select"][name="category"]', this.elWrapper);

    if (!elSelect) return;

    const { category } = this.layoutInstance;
    const value = items.find(({ id }) => (id === category))?.id || DEFAULT_CATEGORY.value;
    const optionsHtml = items.map(({ id, name }) => `<option value="${id}">${name}</option>`);

    elSelect.insertAdjacentHTML('beforeend', optionsHtml.join(''));
    elSelect.value = value;

    this.initSelect(elSelect);
  };

  initSort = () => {
    const elSelect = dom.getElement('[data-widget="select"][name="sortBy"]', this.elWrapper);

    if (!elSelect) return;

    const { sortBy } = this.layoutInstance;

    elSelect.value = sortBy;
    this.initSelect(elSelect);
  };

  initSelect = (elSelect) => {
    if (!elSelect) return;

    // eslint-disable-next-line no-new
    new SelectWidget(elSelect, SELECT_CONFIG);

    dom.on(elSelect, 'change', this.onChangeSort);
  };

  onChangeSort = ({ target, detail }) => {
    const { name } = target;
    const { value } = detail;
    const {
      updateFetchParams,
      resetPagination,
    } = this.layoutInstance;

    if (!isFunction(updateFetchParams) && !isFunction(resetPagination)) return;

    const isDefaultCategory = name === 'category' && value === DEFAULT_CATEGORY.value;

    if (isFunction(updateFetchParams)) updateFetchParams({ [name]: value }, isDefaultCategory);
    if (isFunction(resetPagination)) resetPagination();
  };
}

export default Sort;
